<template>
  <v-container>
    <v-card class="pa-6" max-width="80%">
      <v-card-title> Control de accesos a Granjas</v-card-title>
      <v-card-text>
        <v-col>
          <v-row class="list">
            <v-text-field v-model="rut" placeholder="ingrese rut" label="Rut" />
            <v-select
              v-model="granja"
              :items="granjas"
              label="sel granja"
              centered
            />
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Fecha ingreso"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                locale="es-CL"
                first-day-of-week="1"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-btn block @click="verUser()">verificar acceso</v-btn>
      </v-card-actions>
    </v-card>
    <v-data-table :headers="headers" :items="datos" />
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",
  data: () => ({
    menu: false,
    date: undefined,
    rut: undefined,
    granja: undefined,
    granjas: [
      { text: "a", value: "a" },
      { text: "b", value: "b" },
      { text: "c", value: "c" },
      { text: "d", value: "d" },
    ],
    headers: [
      { text: "RUT", value: "rut", align: "end", sortable: false },
      { text: "Granja", value: "granja", align: "end", sortable: false },
      { text: "Fecha", value: "date", align: "end", sortable: false },
    ],
    datos: [],
  }),
  methods: {
    verUser() {
      console.log(this.rut, this.granja, this.date);
      let newval = {};
      newval["rut"] = this.rut;
      newval["granja"] = this.granja;
      newval["date"] = this.date;
      this.verData(newval)
        .then((result) => {
          console.log(result);
          if (result) this.datos.push(newval);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    verData(checkval) {
      let va = true;
      // this.datos.forEach((el) => {
      for (let i = 0; i < this.datos.length; i++) {
        const el = this.datos[i];
        if (el.rut == checkval.rut) {
          if (el.granja != checkval.granja) {
            let calc = new Date(checkval.date) - new Date(el.date);
            if (calc <= 259200000) {
              alert(
                "Este rut ha sido ingresado hace menos de 3 dias en la granja " +
                  el.granja
              );
              va = false;
              break;
            }
          }
        }
      }
      return Promise.resolve(va);
    },
  },
};
</script>
